import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    NavLink,
    withRouter,
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import NorrmLogoWhite from "../assets/images/norrm-logo-white.png";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };        
    }

    render() {
        var mixcloudPlayer = "0px";
        if(this.props.isAudioPlay === "mixcloud"){
            mixcloudPlayer = "60px"
        }else{
            mixcloudPlayer = "0px";
        }

        return (
            <Box className='main-footer' sx={{marginBottom:mixcloudPlayer}}>
                <Grid container>
                    <Grid item xs={12} md={3} className='footer-image'>
                        <Link to='/'><img alt="norrm logo" src={NorrmLogoWhite}/></Link>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box sx={{padding:"10px", minHeight:{md:"170px", xs:"50px"}}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={NavLink} to="/" exact>Home</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={NavLink} to="/about" exact>About</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={NavLink} to="/show" exact>Shows</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={NavLink} to="/program" exact>Programs</Typography>
                        </Box>
                        <Box sx={{padding:"10px"}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Jl. Cihapit No. 6</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Bandung 40121, ID</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box sx={{padding:"10px", minHeight:{md:"170px", xs:"50px"}}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://open.spotify.com/user/s9jyldvaqdawu7ctyyrure8up?si=JgxZiWPKR-mZ5y9zSE88UQ" }} target="_blank" rel="noopener noreferrer">Spotify</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://www.youtube.com/channel/UCOENRjPGPhfC_PIjgxgmOiQ?view_as=subscriber" }} target="_blank" rel="noopener noreferrer">Youtube</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://www.instagram.com/norrm" }} target="_blank" rel="noopener noreferrer">Instagram</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://www.mixcloud.com/norrm/" }} target="_blank" rel="noopener noreferrer">Mixcloud</Typography>
                        </Box>
                        <Box sx={{padding:"10px"}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px", fontWeight:600}}>Business Inquiry :</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "tel:+628122380324" }} target="_blank" rel="noopener noreferrer">Phone</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "mailto:radio@norrm.com" }} target="_blank" rel="noopener noreferrer">Email</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box sx={{padding:"10px", minHeight:{md:"170px", xs:"50px"}}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://www.gggrammars.com/" }} target="_blank" rel="noopener noreferrer">Grammars</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}} component={Link} to={{ pathname: "https://dsh.technology/" }} target="_blank" rel="noopener noreferrer">Dengan Senang Hati</Typography>
                        </Box>
                        <Box sx={{padding:"10px", display:"none"}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Terms and Conditions</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Cookies and Privacy Policy</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Return and Refund Policy</Typography>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>Size Chart</Typography>                                                        
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box sx={{padding:"10px", minHeight:{md:"170px", xs:"50px"}}}>
                            <Typography sx={{fontSize:"13px", letterSpacing:"1px"}}>All rights reserved. All Trademarks referred to are the property of their respective owners</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));