import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    NavLink,
    withRouter,
} from "react-router-dom";

import { loadMoreProduct } from "../../redux/actions/shops";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class Shops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore(){
        let page = (this.props.product.length / process.env.REACT_APP_PERPAGE) + 1;
        this.props.loadMoreProduct({slug:"all", page:page, perpage:process.env.REACT_APP_PERPAGE});
        // .then((res)=>{
            // console.log(res);
            // if(res.fullload !== undefined){
            //     ctx.setState({canLoadmore:false});
            // }
        // })
    }

    render() {
        let loadmoreButton = "";
        let productContainer = "";
        let openingContainer = "";
        let products = this.props.product;
        if(this.props.minusOne !== undefined){
            products = products.slice(0,-1);
            openingContainer = <Grid item className='grid-container-item'>
                <Typography component={NavLink} to={"/product"} className="items-opening">ALL PRODUCTS</Typography>
            </Grid>;
        }

        if(products.length !== 0){
            productContainer = products.map((item, idx)=>{
                return(
                    <Grid item 
                        key={idx}
                        data-quick={idx} 
                        className='artist-container-item' 
                        component={NavLink} 
                        to={"/product/"+item.slug}
                        sx={{backgroundImage:"url("+encodeURI(item.image[0]?.url)+") !important"}}
                    >
                        {(item.image.length !== 0)?"":<Typography>{item.name}</Typography>}
                    </Grid>
                )
            })
            loadmoreButton = <Box className='archive-loadmore-container' sx={{width:"100%", borderTop:"1px solid #000"}}>
                {this.props.productFullLoaded !== true?<Button sx={{width:"100%"}} onClick={this.loadMore}>Load More</Button>:""}                        
            </Box>
        }else{
            productContainer = <Box sx={{textAlign:"center", padding:10, minHeight:320, width:"100vw", display:"flex",alignItems: "center",justifyContent: "center"}}>
                No Products Available
            </Box>
        }

        return (
            <>
                <Grid container spacing={0} className="grid-container">
                    {openingContainer}
                    {productContainer}
                </Grid>
                {loadmoreButton}
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadMoreProduct: data => dispatch(loadMoreProduct(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shops));